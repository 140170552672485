@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700;900&family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&display=swap');

html,
body {
  font-family: 'MerriweatherSans-Light', sans-serif;
}

html {
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* :root {
  --primary-color: rgba(20, 29, 47, 1);
  --secondary-color: #1e2a47;
  --border-color: rgba(194, 183, 183, 0.1);
  --text-color: #fff;
} */

/* light theme */

/* html.light {
  --primary-color: rgb(243, 244, 246);
  --secondary-color: white;
  --border-color: rgba(20, 29, 47, 0.1);
  --text-color: rgba(20, 29, 47, 1);
} */

body {
  margin: 0;
  padding: 0;
  font-family: 'MerriweatherSans-Light', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--textPrimary);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'MerriweatherSans-SemiBold', 'MerriweatherSans-Light',
    monospace;
}

@media (max-width: 480px) {
  html {
    font-size: 12px;
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsla(221, 42%, 22%, 0.773);
  }
  100% {
    background-color: hsla(221, 42%, 22%, 0.773);
  }
}

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animate-marquee {
  animation: marquee 25s linear infinite;
}

.animate-marquee2 {
  animation: marquee2 25s linear infinite;
}

.feature-card {
  position: relative;
  overflow: hidden;
}

/* Create the border effect */
.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid transparent;
  border-radius: 10px;
  background: conic-gradient(#0ea5e9 20deg, transparent 120deg);
  background-size: 400% 400%;
  z-index: -1;
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.feature-card-inner {
  position: relative;
  z-index: 1;
}
